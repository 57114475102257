import React from "react";
// import Embroidery from "./Embroidery";

function Sleev() {
  return (
    <>
      <section class="about-area about-area2 section-padding">
        <div class="container" style={{ marginTop: "70px" }}>
          {" "}
          <div class="section-heading text-center">
            <h2 class="section__title"> Sleeve Digitizing</h2>
          </div>
          <div class="row pt-5">
            <div class="col-lg-6">
              <div class="about-item about-item-left">
                <ul class="list-items">
                  <li>
                    <span class="la la-certificate"></span>
                    <h3>Bring Out Your Real Style!</h3>
                    <p>
                      Here’s your chance to bring out the real fashion guru in
                      you with our exclusive sleeve digitizing services. With
                      the leading industry experts working at your side, you can
                      be sure of getting the most amazing and exquisitely
                      designed digitized file for even the most complex artwork.{" "}
                    </p>
                  </li>{" "}
                  <li>
                    <span class="la la-certificate"></span>
                    <h3>
                      Dotemb Digitizing – Helping Businesses Transform Into
                      Brands!
                    </h3>
                    <p>
                      Today business is all about getting the right reach and
                      visibility with unique marketing tactics, and when it
                      comes to branding, nothing beats the charm and appeal of
                      sleeves embroidery designs. Our master digitizers have
                      years of experience in transforming even the most complex
                      embroidery designs into the finest digitized formats.{" "}
                      <ul class="list-items">
                        <li>
                          Whether it’s chest embroidery or sleeves embroidery
                          design, with Dotemb digitizing working at your side,
                          you can be sure of getting the highest quality
                          standard files that meticulously define your artwork’
                          preserving the tiniest of details.
                        </li>
                      </ul>
                    </p>
                  </li>{" "}
                </ul>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="about-item about-item-right">
                <div class="about-item about-item-left">
                  <ul class="list-items">
                    <li>
                      <span class="la la-bullseye"></span>
                      <h3>Fastest Turnaround Time for Sleeve Digitizing</h3>
                      <p>
                        {" "}
                        <ul class="list-items">
                          <li>
                            At Dotemb Digitizing, you can now get the best bang
                            for your buck while getting the fastest turnaround
                            time. You can always choose a cheaper route into
                            sleeve embroidery by opting for our intricate and
                            expert digitizing services. No matter where you are
                            situated, we can get back to you in a matter of
                            hours. Not only that, we can assist you with your
                            sleeve digitizing within the
                          </li>
                          <li>
                            {" "}
                            All you have to do is contact one of our customer
                            support agents, and we’ll back to you as soon as
                            possible. Our friendly customer service itself is a
                            perk that you’ll get once you purchase from us.
                            Moreover, with a retention rate of over 90%, we know
                            how to cater to our old customers, and we wouldn’t
                            worry about taking you on board with us. If you are
                            worried about our after-sale services, fret not;
                            because we provide the finest digitizing services in
                            no time.
                          </li>
                        </ul>
                      </p>
                    </li>{" "}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>{" "}
      <section class="form-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="contact-img contact-tilt">
                <div class="section-heading">
                  <p class="section__meta">#welcome to Dotemb</p>
                  <h2 class="section__title">
                    Fast, Efficient and Affordable
                    <br />
                    Embroidery Digitizing Services
                  </h2>
                </div>
                <div class="section-description">
                  <p class="section__desc">
                    If you are looking for a digitizing company that can deliver
                    you the peak quality standards and fastest trunaround time,
                    then you are at the right place.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="contact-form-action contact-form-item">
                <p class="section__meta text-center">REQUEST A QUOTE</p>
                <form method="post">
                  {" "}
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-user form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-envelope-o form-icon"></i>
                        <input
                          class="form-control"
                          type="email"
                          name="email"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-phone form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Number"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-pencil-square form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Subject"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group message-group">
                        <i class="la la-pencil form-icon"></i>
                        <textarea
                          class="form-control message-control"
                          placeholder="Message"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group mb-0">
                        <button class="theme-btn" type="button">
                          send message <span class="la la-caret-right"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Sleev;
