import React from "react";

function LogoDigi() {
  return (
    <>
      <section class="about-area about-area2 section-padding">
        <div class="container" style={{ marginTop: "70px" }}>
          <div class="section-heading text-center">
            <h2 class="section__title"> Logo digitizing</h2>
          </div>
          <p className="pt-5">
            Dotemb digitizing delivers high-quality professional digitizing
            solutions to all businesses and industries for all types of fabrics
            and textures. We have some of the most experienced and skilled
            digitizers ready to deliver you exquisite and completely scalable
            logo digitizing services. We have remained one of the top choices
            for leading businesses from the USA, Australia, Canada, and the UK.
            <br />
            Are you looking to get your artwork transformed into a digitized
            format? ORDER NOW and experience logo digitizing with a difference!
          </p>
          <div class="row mt-5">
            <div class="col-lg-6">
              <div class="about-item about-item-left">
                <ul class="list-items">
                  <li>
                    <span class="la la-certificate"></span>
                    <h3>
                      Dotemb Digitizing – Logo Digitizing Experts at Your
                      Service
                    </h3>
                    <p>
                      As a leading digitizing services business in the USA, we
                      offer a complete range of logo digitizing services for all
                      fabrics and textures.
                      <br />
                      With one of the biggest in-house digitizing
                      infrastructures, the latest industry software,
                      cutting-edge hardware, and the best digitizers in the
                      industry, we are ideally suited for businesses and
                      individuals to get started with all logo digitizing
                      orders. For decades, we have retained our passion for
                      delivering the highest standard services to clients across
                      three continents at phenomenally competitive pricing.
                      Digitizing a logo for embroidery is our forte, and we have
                      been excelling at it for a number of years.
                    </p>
                  </li>{" "}
                  <li>
                    <span class="la la-certificate"></span>
                    <h3>How Does Logo Digitizing Service Works?</h3>
                    <p>
                      Logo digitizing is the process of transforming your logo
                      design (raster format) into scalable and machine-readable
                      artwork (vector design). We use logo digitizing software
                      to make sure we fetch the best results. Vectorization of
                      logo adds the depth of scalability to it, which enables
                      its use across all marketing materials without having to
                      worry about the pixels or inferior quality. We are
                      offering logo digitizing services for all major formats to
                      give you complete flexibility and freedom of use.
                    </p>
                  </li>{" "}
                </ul>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="about-item about-item-right">
                <div class="about-item about-item-left">
                  <ul class="list-items">
                    <li>
                      <span class="la la-bullseye"></span>
                      <h3>Do You Need Logo Digitizing Services?</h3>
                      <p>
                        Logos are the prerequisite marketing material for all
                        businesses. Logos are a visual representation of all
                        businesses that are extensively used for all other
                        marketing material, including websites, forms,
                        brochures, caps, t-shirts, etc. An exclusively designed
                        logo design will help transform your business into a
                        brand by reflecting a refined look of your business
                        model. At Dotemb digitizing, we ensure your logo stays
                        as attractive and possible with detailed attention to
                        the subtlest of the curve while adding the requisite
                        depth to give it the scalability required to be used for
                        all marketing purposes, including caps, t-shirts,
                        shirts, jackets, sleeves, etc.
                      </p>
                    </li>{" "}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="form-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="contact-img contact-tilt">
                <div class="section-heading">
                  <p class="section__meta">#welcome to Dotemb</p>
                  <h2 class="section__title">
                    Fast, Efficient and Affordable
                    <br />
                    Embroidery Digitizing Services
                  </h2>
                </div>
                <div class="section-description">
                  <p class="section__desc">
                    If you are looking for a digitizing company that can deliver
                    you the peak quality standards and fastest trunaround time,
                    then you are at the right place.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="contact-form-action contact-form-item">
                <p class="section__meta text-center">REQUEST A QUOTE</p>
                <form method="post">
                  {" "}
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-user form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-envelope-o form-icon"></i>
                        <input
                          class="form-control"
                          type="email"
                          name="email"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-phone form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Number"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-pencil-square form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Subject"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group message-group">
                        <i class="la la-pencil form-icon"></i>
                        <textarea
                          class="form-control message-control"
                          placeholder="Message"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group mb-0">
                        <button class="theme-btn" type="button">
                          send message <span class="la la-caret-right"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default LogoDigi;
