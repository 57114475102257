import React from "react";

function Blog() {
  return (
    <>
    <section class="card-area section-padding-top blog-four-column">
    <div class="container" style={{marginTop:"70px"}}>
    <div class="row">
            <div class="col-lg-12">
                <div class="section-heading text-center">
                   
                    
                    <h2 class="section__title">Let's See Our Blogs</h2>
                </div>
            </div>
        </div>
        <div class="row card-wrap mt-4">
            <div class="col-lg-3 col-sm-6">
                <div class="card-item card-item-10">
                    <div class="card">
                        <div class="card-image">
                            <img src="assets/images/img1.jpg" class="card-img-top" alt=""/>
                        </div>
                        <div class="card-body">
                            <h5 class="card-subtitle">June 02, 2018</h5>
                            <h3 class="card-title"><a href="blog-single.html" class="card__link">Standard post with a single image</a></h3>
                            <div class="card-separator"></div>
                            <p class="card-text">Sagittis ante posuere ac. Vivamus luctus</p>
                            <div class="card-action">
                                <a href="blog-single.html" class="theme-btn">continue <span class="la la-caret-right"></span></a>
                                <div class="dropdown">
                                    <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span class="fa fa-share"></span>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <a class="dropdown-item" href="#"><i class="fa fa-facebook"></i></a>
                                        <a class="dropdown-item" href="#"><i class="fa fa-twitter"></i></a>
                                        <a class="dropdown-item" href="#"><i class="fa fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="card-item card-item-10">
                    <div class="card">
                        <div class="card-image">
                            <img src="assets/images/img8.jpg" class="card-img-top" alt=""/>
                        </div>
                        <div class="card-body">
                            <h5 class="card-subtitle">June 02, 2018</h5>
                            <h3 class="card-title"><a href="blog-single.html" class="card__link">Standard post with a single image</a></h3>
                            <div class="card-separator"></div>
                            <p class="card-text">Sagittis ante posuere ac. Vivamus luctus</p>
                            <div class="card-action">
                                <a href="blog-single.html" class="theme-btn">continue <span class="la la-caret-right"></span></a>
                                <div class="dropdown">
                                    <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span class="fa fa-share"></span>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink2">
                                        <a class="dropdown-item" href="#"><i class="fa fa-facebook"></i></a>
                                        <a class="dropdown-item" href="#"><i class="fa fa-twitter"></i></a>
                                        <a class="dropdown-item" href="#"><i class="fa fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="card-item card-item-10">
                    <div class="card">
                        <div class="card-image">
                            <img src="assets/images/img9.jpg" class="card-img-top" alt=""/>
                        </div>
                        <div class="card-body">
                            <h5 class="card-subtitle">June 02, 2018</h5>
                            <h3 class="card-title"><a href="blog-single.html" class="card__link">Standard post with a single image</a></h3>
                            <div class="card-separator"></div>
                            <p class="card-text">Sagittis ante posuere ac. Vivamus luctus</p>
                            <div class="card-action">
                                <a href="blog-single.html" class="theme-btn">continue <span class="la la-caret-right"></span></a>
                                <div class="dropdown">
                                    <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span class="fa fa-share"></span>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink3">
                                        <a class="dropdown-item" href="#"><i class="fa fa-facebook"></i></a>
                                        <a class="dropdown-item" href="#"><i class="fa fa-twitter"></i></a>
                                        <a class="dropdown-item" href="#"><i class="fa fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="card-item card-item-10">
                    <div class="card">
                        <div class="card-image">
                            <img src="assets/images/img1.jpg" class="card-img-top" alt=""/>
                        </div>
                        <div class="card-body">
                            <h5 class="card-subtitle">June 02, 2018</h5>
                            <h3 class="card-title"><a href="blog-single.html" class="card__link">Standard post with a single image</a></h3>
                            <div class="card-separator"></div>
                            <p class="card-text">Sagittis ante posuere ac. Vivamus luctus</p>
                            <div class="card-action">
                                <a href="blog-single.html" class="theme-btn">continue <span class="la la-caret-right"></span></a>
                                <div class="dropdown">
                                    <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink4" data-toggle="dropdown" aria-haspopup="true"
                                       aria-expanded="false">
                                        <span class="fa fa-share"></span>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink4">
                                        <a class="dropdown-item" href="#"><i class="fa fa-facebook"></i></a>
                                        <a class="dropdown-item" href="#"><i class="fa fa-twitter"></i></a>
                                        <a class="dropdown-item" href="#"><i class="fa fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="card-item card-item-10">
                    <div class="card">
                        <div class="card-image">
                            <img src="assets/images/img8.jpg" class="card-img-top" alt=""/>
                        </div>
                        <div class="card-body">
                            <h5 class="card-subtitle">June 02, 2018</h5>
                            <h3 class="card-title"><a href="blog-single.html" class="card__link">Standard post with a single image</a></h3>
                            <div class="card-separator"></div>
                            <p class="card-text">Sagittis ante posuere ac. Vivamus luctus</p>
                            <div class="card-action">
                                <a href="blog-single.html" class="theme-btn">continue <span class="la la-caret-right"></span></a>
                                <div class="dropdown">
                                    <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink5" data-toggle="dropdown" aria-haspopup="true"
                                       aria-expanded="false">
                                        <span class="fa fa-share"></span>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink5">
                                        <a class="dropdown-item" href="#"><i class="fa fa-facebook"></i></a>
                                        <a class="dropdown-item" href="#"><i class="fa fa-twitter"></i></a>
                                        <a class="dropdown-item" href="#"><i class="fa fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="card-item card-item-10">
                    <div class="card">
                        <div class="card-image">
                            <img src="assets/images/img9.jpg" class="card-img-top" alt="" />
                        </div>
                        <div class="card-body">
                            <h5 class="card-subtitle">June 02, 2018</h5>
                            <h3 class="card-title"><a href="blog-single.html" class="card__link">Standard post with a single image</a></h3>
                            <div class="card-separator"></div>
                            <p class="card-text">Sagittis ante posuere ac. Vivamus luctus</p>
                            <div class="card-action">
                                <a href="blog-single.html" class="theme-btn">continue <span class="la la-caret-right"></span></a>
                                <div class="dropdown">
                                    <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink6" data-toggle="dropdown" aria-haspopup="true"
                                       aria-expanded="false">
                                        <span class="fa fa-share"></span>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink6">
                                        <a class="dropdown-item" href="#"><i class="fa fa-facebook"></i></a>
                                        <a class="dropdown-item" href="#"><i class="fa fa-twitter"></i></a>
                                        <a class="dropdown-item" href="#"><i class="fa fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="card-item card-item-10">
                    <div class="card">
                        <div class="card-image">
                            <img src="assets/images/img8.jpg" class="card-img-top" alt=""/>
                        </div>
                        <div class="card-body">
                            <h5 class="card-subtitle">June 02, 2018</h5>
                            <h3 class="card-title"><a href="blog-single.html" class="card__link">Standard post with a single image</a></h3>
                            <div class="card-separator"></div>
                            <p class="card-text">Sagittis ante posuere ac. Vivamus luctus</p>
                            <div class="card-action">
                                <a href="blog-single.html" class="theme-btn">continue <span class="la la-caret-right"></span></a>
                                <div class="dropdown">
                                    <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink7" data-toggle="dropdown" aria-haspopup="true"
                                       aria-expanded="false">
                                        <span class="fa fa-share"></span>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink7">
                                        <a class="dropdown-item" href="#"><i class="fa fa-facebook"></i></a>
                                        <a class="dropdown-item" href="#"><i class="fa fa-twitter"></i></a>
                                        <a class="dropdown-item" href="#"><i class="fa fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="card-item card-item-10">
                    <div class="card">
                        <div class="card-image">
                            <img src="assets/images/img9.jpg" class="card-img-top" alt=""/>
                        </div>
                        <div class="card-body">
                            <h5 class="card-subtitle">June 02, 2018</h5>
                            <h3 class="card-title"><a href="blog-single.html" class="card__link">Standard post with a single image</a></h3>
                            <div class="card-separator"></div>
                            <p class="card-text">Sagittis ante posuere ac. Vivamus luctus</p>
                            <div class="card-action">
                                <a href="blog-single.html" class="theme-btn">continue <span class="la la-caret-right"></span></a>
                                <div class="dropdown">
                                    <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink8" data-toggle="dropdown" aria-haspopup="true"
                                       aria-expanded="false">
                                        <span class="fa fa-share"></span>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink8">
                                        <a class="dropdown-item" href="#"><i class="fa fa-facebook"></i></a>
                                        <a class="dropdown-item" href="#"><i class="fa fa-twitter"></i></a>
                                        <a class="dropdown-item" href="#"><i class="fa fa-instagram"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <div class="pagination-wrap pt-4">
            <nav aria-label="Page navigation">
                <ul class="pagination text-center justify-content-center">
                    <li class="page-item">
                        <a class="page-link" href="#" aria-label="Previous">
                            <span aria-hidden="true"><i class="la la-caret-left"></i></span>
                        </a>
                    </li>
                    <li class="page-item active"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                   
                    <li class="page-item">
                        <a class="page-link" href="#" aria-label="Next">
                            <span aria-hidden="true"><i class="la la-caret-right"></i></span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div> */}
    </div>
</section>
   
    </>
  );
}
export default Blog;
