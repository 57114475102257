import React from "react";
// import Embroidery from "./Embroidery";

function EmbroideryPatch() {
  return (
    <>
      <section class="about-area about-area2 section-padding">
        <div class="container" style={{ marginTop: "70px" }}>
          <div class="section-heading text-center">
            <h2 class="section__title"> Embroidery Patches Digitizing</h2>
          </div>
          <div class="row mt-5">
            <div class="col-lg-6">
              <div class="about-item about-item-left">
                <ul class="list-items">
                  <li>
                    <span class="la la-certificate"></span>
                    <h3>Custom Logo Embroidery Patches</h3>
                    <p>
                      Dotemb Digitizing is a leading name in the embroidery
                      digitizing industry in the USA, offering reliable and
                      affordable solutions for all digitizing services to
                      individuals and companies across three continents. Our
                      range of services includes patches embroidery, custom
                      embroidery, vector art conversion and much more.
                    </p>
                  </li>{" "}
                  <li>
                    <span class="la la-certificate"></span>
                    <h3>Patches Embroidery with a Difference</h3>
                    <p>
                      At Dotemb digitizing we take pride in offering the best,
                      consistent and affordable digitizing solutions to clients
                      across the USA, UK, Canada, and Australia. We are one of
                      the pioneers of the industry with decades of experience
                      serving national and international clients.
                      <br />
                      While you may find many cheaper patches embroidery
                      services, none compete for the wroth for value with our
                      quality and deliverability. Over the decades, we have
                      built a massive purpose-built infrastructure, coupled with
                      the best digitizers and cutting-edge software to ensure
                      premium quality services to our clients. With in-house
                      digitizing machines, we ensure each patches embroidery
                      order is precisely made to meet the clients’ requirements.
                    </p>
                  </li>{" "}
                </ul>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="about-item about-item-right">
                <div class="about-item about-item-left">
                  <ul class="list-items">
                    <li>
                      <span class="la la-bullseye"></span>
                      <h3>Patches Embroidery Done the Right Way</h3>
                      <p>
                        At Dotemb Digitizing, we are all about delivering
                        clients’ the best quality and consistent digitizing
                        services across the complete operational spectrum. Being
                        one of the most experienced and respected companies in
                        the industry, we have the resources and expertise needed
                        to ensure the highest standard and timely deliverance of
                        bulk orders. We have hand-picked our team of master
                        digitizers for their passion, skills, and experience in
                        the field while also enabling them to stay at par with
                        modern industry requirements through rigorous training
                        sessions.
                      </p>
                    </li>{" "}
                    <li>
                      <span class="la la-bullseye"></span>
                      <h3>Fastest Turnaround Time for Embroidery Patches</h3>
                      <p>
                        At Dotemb Digitizing, we’re all about providing quality
                        embroidery patches at budget-friendly rates and fast
                        turnaround times. If you want to achieve the best custom
                        logo embroidery patches, we are here at your service. No
                        matter where you are situated, we can provide you with
                        impeccable service within the comfort of your home.
                        <br />
                        Our custom embroidery patches provide value for money
                        while adhering to the time constraints of fast
                        turnaround periods. On top of that, we provide rush
                        orders as well. So, if you’re stuck between choosing
                        quality customers and fast turnaround times, your search
                        quest is over now. We provide both of these perks for
                        free.
                      </p>
                    </li>{" "}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>{" "}
      <section class="form-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="contact-img contact-tilt">
                <div class="section-heading">
                  <p class="section__meta">#welcome to Dotemb</p>
                  <h2 class="section__title">
                    Fast, Efficient and Affordable
                    <br />
                    Embroidery Digitizing Services
                  </h2>
                </div>
                <div class="section-description">
                  <p class="section__desc">
                    If you are looking for a digitizing company that can deliver
                    you the peak quality standards and fastest trunaround time,
                    then you are at the right place.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="contact-form-action contact-form-item">
                <p class="section__meta text-center">REQUEST A QUOTE</p>
                <form method="post">
                  {" "}
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-user form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-envelope-o form-icon"></i>
                        <input
                          class="form-control"
                          type="email"
                          name="email"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-phone form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Number"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-pencil-square form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Subject"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group message-group">
                        <i class="la la-pencil form-icon"></i>
                        <textarea
                          class="form-control message-control"
                          placeholder="Message"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group mb-0">
                        <button class="theme-btn" type="button">
                          send message <span class="la la-caret-right"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default EmbroideryPatch;
