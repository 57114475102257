import React from "react";

function Portfolio() {
  return (
    <>
      <section class="card-area section-padding-top blog-four-column">
        <div class="container">
          <div class="section-heading text-center mt-5">
            <p class="section__meta">#our portfolio</p>
            <h4 class="section__title">
              Lets Take a Review of Our Recent Projects
            </h4>
          </div>
          <h2 class="section__title mb-3 mt-5" style={{ fontWeight: "600" }}>
            Flat Digitizing
          </h2>
          <div class="row card-wrap mt-5">
            <div class="col-lg-3 col-sm-6">
              <div class="card-item card-item-10">
                <div class="card">
                  <div class="card-image">
                    <img
                      src="assets/images/eagle.png"
                      class="card-img-top align-aspect-ratio"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="card-item card-item-10">
                <div class="card">
                  <div class="card-image">
                    <img
                      src="assets/images/Frame20.png"
                      class="card-img-top align-aspect-ratio"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>{" "}
            <div class="col-lg-3 col-sm-6">
              <div class="card-item card-item-10">
                <div class="card">
                  <div class="card-image">
                    <img
                      src="assets/images/IMG_68771.png"
                      class="card-img-top align-aspect-ratio"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>{" "}
            <div class="col-lg-3 col-sm-6">
              <div class="card-item card-item-10">
                <div class="card">
                  <div class="card-image">
                    <img
                      src="assets/images/3DPuff2.png"
                      class="card-img-top align-aspect-ratio"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h2 class="section__title mb-3 mt-5" style={{ fontWeight: "600" }}>
            Applique Digitizing
          </h2>
          <div class="row card-wrap mt-5">
            <div class="col-lg-3 col-sm-6">
              <div class="card-item card-item-10">
                <div class="card">
                  <div class="card-image">
                    <img
                      src="assets/images/Frame14.png"
                      class="card-img-top align-aspect-ratio"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="card-item card-item-10">
                <div class="card">
                  <div class="card-image">
                    <img
                      src="assets/images/puff.png"
                      class="card-img-top align-aspect-ratio"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="card-item card-item-10">
                <div class="card">
                  <div class="card-image">
                    <img
                      src="assets/images/Frame15.png"
                      class="card-img-top align-aspect-ratio"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="card-item card-item-10">
                <div class="card">
                  <div class="card-image">
                    <img
                      src="assets/images/Frame16.png"
                      class="card-img-top align-aspect-ratio"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h2 class="section__title mb-3 mt-5" style={{ fontWeight: "600" }}>
            3D / PUFF Digitizing
          </h2>
          <div class="row card-wrap mt-5">
            <div class="col-lg-3 col-sm-6">
              <div class="card-item card-item-10">
                <div class="card">
                  <div class="card-image">
                    <img
                      src="assets/images/Frame12.png"
                      class="card-img-top align-aspect-ratio"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>{" "}
            <div class="col-lg-3 col-sm-6">
              <div class="card-item card-item-10">
                <div class="card">
                  <div class="card-image">
                    <img
                      src="assets/images/puff1.png"
                      class="card-img-top align-aspect-ratio"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="card-item card-item-10">
                <div class="card">
                  <div class="card-image">
                    <img
                      src="assets/images/Frame13.png"
                      class="card-img-top align-aspect-ratio"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="card-item card-item-10">
                <div class="card">
                  <div class="card-image">
                    <img
                      src="assets/images/IMG_68651.png"
                      class="card-img-top align-aspect-ratio"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h2 class="section__title mb-3 mt-5" style={{ fontWeight: "600" }}>
            Cap Digitizing Service
          </h2>
          <div class="row card-wrap mt-5">
            <div class="col-lg-3 col-sm-6">
              <div class="card-item card-item-10">
                <div class="card">
                  <div class="card-image">
                    <img
                      src="assets/images/IMG_6879.png"
                      class="card-img-top align-aspect-ratio"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="card-item card-item-10">
                <div class="card">
                  <div class="card-image">
                    <img
                      src="assets/images/IMG_68491.png"
                      class="card-img-top align-aspect-ratio"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>{" "}
            <div class="col-lg-3 col-sm-6">
              <div class="card-item card-item-10">
                <div class="card">
                  <div class="card-image">
                    <img
                      src="assets/images/hat1.png"
                      class="card-img-top align-aspect-ratio"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="card-item card-item-10">
                <div class="card">
                  <div class="card-image">
                    <img
                      src="assets/images/IMG_68461.png"
                      class="card-img-top align-aspect-ratio"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          <h2 class="section__title mb-3 mt-5" style={{ fontWeight: "600" }}>
            Small Letters Digitizing
          </h2>
          <div class="row card-wrap mt-5">
            <div class="col-lg-3 col-sm-6">
              <div class="card-item card-item-10">
                <div class="card">
                  <div class="card-image">
                    <img
                      src="assets/images/small1.png"
                      class="card-img-top align-aspect-ratio"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="card-item card-item-10">
                <div class="card">
                  <div class="card-image">
                    <img
                      src="assets/images/small2.png"
                      class="card-img-top align-aspect-ratio"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="card-item card-item-10">
                <div class="card">
                  <div class="card-image">
                    <img
                      src="assets/images/small3.png"
                      class="card-img-top align-aspect-ratio"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6">
              <div class="card-item card-item-10">
                <div class="card">
                  <div class="card-image">
                    <img
                      src="assets/images/small4.png"
                      class="card-img-top align-aspect-ratio"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          <h2 class="section__title mb-3 mt-5" style={{ fontWeight: "600" }}>
            Jacket Back Digitizing
          </h2>
          <div class="row card-wrap mt-5">
            <div class="col-lg-3 col-sm-6">
              <div class="card-item card-item-10">
                <div class="card">
                  <div class="card-image">
                    <img
                      src="assets/images/jacket1.png"
                      class="card-img-top align-aspect-ratio"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="card-item card-item-10">
                <div class="card">
                  <div class="card-image">
                    <img
                      src="assets/images/jacket2.png"
                      class="card-img-top align-aspect-ratio"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="card-item card-item-10">
                <div class="card">
                  <div class="card-image">
                    <img
                      src="assets/images/jacket3.png"
                      class="card-img-top align-aspect-ratio"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="card-item card-item-10">
                <div class="card">
                  <div class="card-image">
                    <img
                      src="assets/images/full4.png"
                      class="card-img-top align-aspect-ratio"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </section>
    </>
  );
}

export default Portfolio;
