import React from "react";

function Puff() {
  return (
    <>
      <section class="about-area about-area2 section-padding">
        <div class="container" style={{ marginTop: "100px" }}>
          <div class="section-heading text-center">
            <h2 class="section__title">3D/Puff Digitizing</h2>
          </div>
          <div class="row pt-5">
            {" "}
            <div class="col-lg-6">
              <div class="about-item about-item-right">
                <div class="about-item about-item-left">
                  <ul class="list-items">
                    <li>
                      <span class="la la-bullseye"></span>
                      <h3>
                        3D Puff Embroidery Hats: Powered by Tech and Driven by
                        Passion{" "}
                      </h3>
                      <p>
                        <ul class="list-items">
                          <li>
                            At Dotemb Digitizing, we provide quality puff
                            embroidery hats that elevate your crafting
                            experience to a whole new level. With experience
                            spanning decades, we are the pioneers of digitizing
                            services in the country and have seen the industry
                            grow over the decades and ensures to keep pace with
                            respect to the technological advancement as it
                            happens.{" "}
                          </li>
                          <li>
                            {" "}
                            To ensure our superior quality and client
                            satisfaction, we have invested dearly in
                            technological and human resources, keeping us ahead
                            of the competitive curve. You can place an order for
                            3D puff embroidery hats no minimum than 100 stitches
                            per order so that our craftsmanship remains intact.
                          </li>
                          <li>
                            Today, our puff digitizing services encompass all
                            aspects of the service, including pure 3D puff
                            digitizing and 2D embroidery digitizing that
                            specifically focus on the design facet bringing us a
                            refined and dazzling final puff design. We use the
                            latest machinery and industry-standard software to
                            fill up the 3D foam with embroidered borders to
                            ensure exquisite looks and refined quality.{" "}
                          </li>
                        </ul>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="about-item about-item-left">
                <ul class="list-items">
                  <li>
                    <span class="la la-certificate"></span>
                    <h3>3D/Puff Digitizing – We Do it Better than the Most!</h3>
                    <p>
                      <ul class="list-items">
                        <li>
                          {" "}
                          At Dotemb Digitizing, we take pride in delivering the
                          highest standard 3D puff digitizing services at the
                          most competitive rates. We really love to exceed
                          clients’ expectations by dazzling them with
                          exquisitely designed and professionally crafted 3D
                          puff digitized designs.
                        </li>
                        <li>
                          To be honest, we owe our success of 3D digitizing
                          largely to our expert team of passionate and highly
                          skilled digitizers who love to get things done at the
                          highest standards. With an over 90% client retention
                          rate, we are proud to retain our passion and zeal to
                          serve local and international clients from the USA,
                          UK, Canada, and Australia with high-quality,
                          affordable, and timely project completion.
                        </li>
                        <li>Connect Now for a free estimate!</li>
                      </ul>{" "}
                    </p>
                  </li>{" "}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>{" "}
      <section class="form-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="contact-img contact-tilt">
                <div class="section-heading">
                  <p class="section__meta">#welcome to Dotemb</p>
                  <h2 class="section__title">
                    Fast, Efficient and Affordable
                    <br />
                    Embroidery Digitizing Services
                  </h2>
                </div>
                <div class="section-description">
                  <p class="section__desc">
                    If you are looking for a digitizing company that can deliver
                    you the peak quality standards and fastest trunaround time,
                    then you are at the right place.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="contact-form-action contact-form-item">
                <p class="section__meta text-center">REQUEST A QUOTE</p>
                <form method="post">
                  {" "}
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-user form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-envelope-o form-icon"></i>
                        <input
                          class="form-control"
                          type="email"
                          name="email"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-phone form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Number"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-pencil-square form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Subject"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group message-group">
                        <i class="la la-pencil form-icon"></i>
                        <textarea
                          class="form-control message-control"
                          placeholder="Message"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group mb-0">
                        <button class="theme-btn" type="button">
                          send message <span class="la la-caret-right"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Puff;
