import React from "react";
import logo from "../assets/logo.svg";

function Header() {
  return (
    <>
      <section class="header-menu-area">
        {/* <div class="header-top">
        <div class="container">
            <div class="row">
                <div class="col-lg-7">
                    <div class="header-top-info header-left-info">
                        <ul class="info-list">
                            <li><i class="la la-clock-o"></i>Mon-Fri: 08.00 - 17.00</li>
                            <li><i class="la la-map-marker"></i>7/118/40, Sri Ram Avenue, Keeranatham, Coimbatore - 641035</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="header-top-info header-right-info">
                        <ul class="info-list">
                            <li><a href="#"><i class="lab la-facebook-f"></i></a></li>
                            <li><a href="#"><i class="lab la-twitter"></i></a></li>
                            <li><a href="#"><i class="lab la-youtube"></i></a></li>
                            <li>
                                <a href="#" class="theme-btn">
                                    contact us <span class="la la-caret-right"></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
        <div class="header-menu-fluid">
          <div class="container">
            <div class="row align-items-center menu-content">
              <div class="col-lg-3">
                <div class="logo-box">
                  <a href="/" class="logo" title="Prizon">
                    <img src={logo} alt="Prizon" />
                  </a>
                </div>
              </div>
              <div class="col-lg-9">
                <div class="menu-wrapper">
                  <nav class="main-menu">
                    <ul>
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>
                        <a href="/pricing">Pricing</a>
                      </li>
                      <li>
                        <a href="#">Services</a>
                        <ul class="dropdown-menu-item">
                          <li>
                            <a href="/embroidery">Embroidery Digitizing</a>
                          </li>
                          <li>
                            <a href="/vector">Vector Art Conversion</a>
                          </li>
                          <li>
                            <a href="/puff">3D / PUFF Digitizing</a>
                          </li>
                          <li>
                            <a href="/applique">Applique Digitizing</a>
                          </li>
                          <li>
                            <a href="/jacket">Jacket Back Digitizing</a>
                          </li>
                          <li>
                            <a href="logo">Logo Digitizing</a>
                          </li>

                          <li>
                            <a href="cap">Cap Digitizing Service</a>
                          </li>
                          <li>
                            <a href="embroiderypatch">Embroidery Patches</a>
                          </li>
                          <li>
                            <a href="sleev">Sleeve Digitizing</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href="/faq">FAQ</a>
                      </li>
                      <li>
                        <a href="/portfolio">portfolio</a>
                      </li>{" "}
                      {/* <li>
                        <a href="/blog">blog</a>
                      </li>{" "} */}
                      <li>
                        <a href="/contact">contact us</a>
                      </li>
                    </ul>
                  </nav>
                  {/* <div class="logo-right-button">
                            <ul>
                                <li class="button-box"><a href="#" class="theme-btn">get it now <span class="la la-caret-right"></span></a></li>
                            </ul>
                            <div class="side-menu-open">
                                <span class="menu__bar"></span>
                                <span class="menu__bar"></span>
                                <span class="menu__bar"></span>
                            </div>
                        </div> */}
                  <div class="side-nav-container">
                    <div class="humburger-menu">
                      <div class="humburger-menu-lines side-menu-close"></div>
                    </div>
                    <div class="side-menu-wrap">
                      <ul class="side-menu-ul">
                        <li class="sidenav__item">
                          <a href="/">home</a>
                        </li>
                        <li class="sidenav__item">
                          <a href="/pricing">Pricing</a>
                        </li>
                        <li class="sidenav__item">
                          <a href="#">Services</a>
                          <span class="menu-plus-icon"></span>
                          <ul class="side-sub-menu">
                            <li>
                              <a href="/embroidery">Embroidery Digitizing</a>
                            </li>
                            <li>
                              <a href="/vector">Vector Art Conversion</a>
                            </li>
                            <li>
                              <a href="/puff">3D / PUFF Digitizing</a>
                            </li>
                            <li>
                              <a href="/applique">Applique Digitizing</a>
                            </li>
                            <li>
                              <a href="/jacket">Jacket Back Digitizing</a>
                            </li>
                            <li>
                              <a href="/logo">Logo Digitizing</a>
                            </li>

                            <li>
                              <a href="/cap">Cap Digitizing Service</a>
                            </li>
                            <li>
                              <a href="/embroiderypatch">Embroidery Patches</a>
                            </li>
                            <li>
                              <a href="/sleev">Sleeve Digitizing</a>
                            </li>
                          </ul>
                        </li>
                        <li class="sidenav__item">
                          <a href="/faq">FAQ</a>
                        </li>
                        <li class="sidenav__item">
                          <a href="/portfolio">portfolio</a>
                        </li>
                        <li class="sidenav__item">
                          <a href="/contact">contact us</a>
                        </li>
                      </ul>
                      {/* <div class="side-btn-box">
                                    <a href="#" class="theme-btn">get started <span class="la la-caret-right"></span></a>
                                </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Header;
