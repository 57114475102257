import React from "react";

function Contact() {
  return (
    <>
      <section class="contact-area section--padding">
        <div class="container" style={{ marginTop: "70px" }}>
          <div class="row">
            <div class="col-lg-7">
              <div class="section-heading">
                <p class="section__meta">#get in touch</p>
                <h2 class="section__title">Need Any Help? Contact us</h2>
              </div>
              <div class="contact-form-action contact-form-item">
                <form
                  action="mailto:dotembofficial@gmail.com"
                  method="post"
                  enctype="text/plain"
                >
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-user form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-envelope-o form-icon"></i>
                        <input
                          class="form-control"
                          type="email"
                          name="email"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-phone form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Number"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-pencil-square form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Subject"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group message-group">
                        <i class="la la-pencil form-icon"></i>
                        <textarea
                          class="form-control message-control"
                          placeholder="Message"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group mb-0">
                        <button class="theme-btn" type="button">
                          send message <span class="la la-caret-right"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-4 ml-auto">
              <div class="contact-img contact-tilt">
                <img src="assets/images/contact-img.png" alt="" />
              </div>
            </div>
          </div>
          <div class="row contact-address">
            <div class="col-lg-12">
              <div class="contact-heading">
                <h3 class="contact__heading">Address</h3>
              </div>
            </div>
            <div class="col-lg-7 col-sm-12">
              <div class="contact-card">
                <p class="contact__text">
                  <span>
                    <i class="la la-map-marker"></i> 7/118/40, Sri Ram Avenue,
                    Keeranatham, Coimbatore - 641035
                  </span>{" "}
                  <span>
                    <i class="la la-phone"></i>{" "}
                    <a href="tel:+1 562-286-5863">+1 562-286-5863</a>
                  </span>
                  <span>
                    <i class="la la-whatsapp"></i>{" "}
                    <a href="https://wa.me/918438589702">+91 8438589702</a>
                  </span>{" "}
                  <span>
                    <i class="la la-envelope-o"></i>{" "}
                    <a href="mailto:dotembofficial@gmail.com">
                      dotembofficial@gmail.com
                    </a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Contact;
