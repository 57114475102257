import React from "react";

function Vector() {
  return (
    <>
      <section class="about-area about-area2 section-padding">
        <div class="container" style={{ marginTop: "70px" }}>
          <div class="section-heading text-center">
            <h2 class="section__title">Vector Art Conversion</h2>
          </div>
          <div class="row pt-5">
            <div class="col-lg-6">
              <div class="about-item about-item-right">
                <div class="about-item about-item-left">
                  <ul class="list-items">
                    <li>
                      <span class="la la-bullseye"></span>
                      <h3>
                        Professional Vector Artists for Vector Art Conversion
                        Service
                      </h3>
                      <p>
                        <ul class="list-items">
                          <li>
                            {" "}
                            We have talented visual originators who provide
                            remarkable vector artwork services and can actualize
                            your musings into an incredible logo. In the event
                            that you have thoughts for logo creation or for logo
                            patch up, we can help with the logo vector
                            workmanship administration too.
                          </li>
                          <li>
                            In the event that you have your logo duplicate or
                            web arrangement of your logo and require logo
                            vectorization administration, you can get in touch
                            with us.
                          </li>
                          <li>
                            Connect with us for any prerequisite of logo
                            creation directly, starting with no outside help,
                            and get 100% quality answers for your item or
                            business.
                          </li>
                          <li>
                            The work of cheap vector art services at Dotemb
                            Digitization is simple, moderate, and speedy, which
                            are good for printing regardless of the size.
                            Alongside vector workmanship creation, we
                            additionally have administrations for weaving vector
                            digitizing, which can be weaved on different
                            textures and attire.
                          </li>
                          <li>
                            Document Formats offered: .eps, .ai, .cdr, .psd,
                            .pdf and .jpg. On the off chance that your favored
                            arrangement isn't recorded here, if you don't mind,
                            notice so at the season of submitting your request.
                          </li>
                          <li>
                            When you depend on outside suppliers for the
                            high-volume creation of corporate vector art,
                            quality is basic. Solid turn times and the ability
                            to meet occasional pinnacles are additionally
                            significant. Thought Custom Solutions offers custom
                            administrations for your business and goes about as
                            an augmentation of your group, inner frameworks, and
                            procedures. We make vector pictures, vector-based
                            pictures, and do vector outlines. Vector Art or
                            Vector Graphics is expected to plan a shirt.
                          </li>
                          <li>
                            Exceptionally printed shirts are the most well-known
                            attire article these days. Limited-time items
                            organizations and publicizing offices utilize
                            uniquely printed shirts to advance their customers'
                            brands. Other than shirts, there is a wide scope of
                            uniquely printed items accessible to pick.
                          </li>{" "}
                        </ul>{" "}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="about-item about-item-left">
                <ul class="list-items">
                  <li>
                    <span class="la la-certificate"></span>
                    <h3>Vector Artwork Conversion Service</h3>
                    <p>
                      <ul class="list-items">
                        <li>
                          Vector art is a picture made with careful specifying.
                          At Dotemb Digitizing, we provide vector artwork
                          conversion services in the perfect way. Vector
                          pictures can be scaled up or down endlessly without
                          the loss of goals and are consequently the most
                          favored organization for business printing.
                        </li>
                        <li>
                          These pictures likewise give an adaptable, adjustable,
                          simple approach to include the littlest of detail and
                          are the ideal approach to get your picture looking
                          precisely how you need it to be. We likewise furnish
                          incredible line craftsmanship illustrations with
                          mind-boggling enumerating for vector conversion.
                        </li>
                        <li>
                          With our vector artwork service, we can change over
                          any logo or realistic to vector position. All we need
                          from you is a sweep or duplicate of the picture to be
                          improved into the Vector Art group. Vector change is
                          finished by hand (Tablet) and not through a
                          computerized procedure.
                        </li>
                        <li>
                          It guarantees your logo is of the most astounding
                          quality. Record formats offered are .eps, .ai, .cdr,
                          .psd, .pdf and .jpg. In the event that your favored
                          organization isn't recorded here, if it's not too much
                          trouble, notice so at the season of putting in your
                          request. Furthermore, raster to vector art services
                          contains tons of other customization features you can
                          obtain.
                        </li>
                        <li>
                          Alongside vector workmanship transformation
                          administration, we additionally do custom vector art
                          services, for which you can just share your
                          prerequisites, and we would help with the execution.
                        </li>{" "}
                      </ul>{" "}
                    </p>
                  </li>{" "}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="form-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="contact-img contact-tilt">
                <div class="section-heading">
                  <p class="section__meta">#welcome to Dotemb</p>
                  <h2 class="section__title">
                    Fast, Efficient and Affordable
                    <br />
                    Embroidery Digitizing Services
                  </h2>
                </div>
                <div class="section-description">
                  <p class="section__desc">
                    If you are looking for a digitizing company that can deliver
                    you the peak quality standards and fastest trunaround time,
                    then you are at the right place.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="contact-form-action contact-form-item">
                <p class="section__meta text-center">REQUEST A QUOTE</p>
                <form method="post">
                  {" "}
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-user form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-envelope-o form-icon"></i>
                        <input
                          class="form-control"
                          type="email"
                          name="email"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-phone form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Number"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-pencil-square form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Subject"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group message-group">
                        <i class="la la-pencil form-icon"></i>
                        <textarea
                          class="form-control message-control"
                          placeholder="Message"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group mb-0">
                        <button class="theme-btn" type="button">
                          send message <span class="la la-caret-right"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Vector;
