import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "../Commom/Header";
import Footer from "../Commom/Footer";
import Home from "../Pages/Home";
import Pricing from "../Pages/Pricing";
import FAQ from "../Pages/FAQ";
import Portfolio from "../Pages/Portfolio";
import Blog from "../Pages/Blog";
import Contact from "../Pages/Contact";
import Embroidery from "../Pages/Services/Embroidery";
import Vector from "../Pages/Services/Vector";
import Puff from "../Pages/Services/Puff";
import Applique from "../Pages/Services/AppliqueDigitizing";
import Jacket from "../Pages/Services/Jacket";
import LogoDigi from "../Pages/Services/LogoDigi";
import CapDigi from "../Pages/Services/CapDigi";
import EmbroideryPatch from "../Pages/Services/EmbroideryPatch";
import Sleev from "../Pages/Services/Sleev";


const Mainroutes = () => {
  //   const Token = localStorage.getItem("Authtok");

  return (
    <div>
      <Router>
        <Header />

        <Routes>
          <Route exact path={"/"} element={<Home />} />
          <Route exact path={"/pricing"} element={<Pricing />} />
          <Route exact path={"/faq"} element={<FAQ />} />
          <Route exact path={"/portfolio"} element={<Portfolio />} />
          <Route exact path={"/blog"} element={<Blog />} />
          <Route exact path={"/contact"} element={<Contact />} />
          <Route exact path={"/embroidery"} element={<Embroidery />} />
          <Route exact path={"/vector"} element={<Vector />} />
          <Route exact path={"/puff"} element={<Puff />} />
          <Route exact path={"/applique"} element={<Applique />} />
          <Route exact path={"/jacket"} element={<Jacket />} />
          <Route exact path={"/logo"} element={<LogoDigi />} />
          <Route exact path={"/cap"} element={<CapDigi />} />
          <Route exact path={"/embroiderypatch"} element={<EmbroideryPatch />} />
          <Route exact path={"/sleev"} element={<Sleev />} />
      

  
          {/* <Route path={"*"} element={<Error404 />} />
         <Route exact path={"*"} element={<SomethingWentWrong />} /> */}
        </Routes>
        <Footer />
        {/* </Suspense> */}
      </Router>
    </div>
  );
};

export default Mainroutes;
