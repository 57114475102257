import React, { useState, useEffect } from "react";
import logo from "../assets/logo.svg";
import { FaXTwitter } from "react-icons/fa6";

function Footer() {
  const [showScrollButton, setShowScrollButton] = useState(false);
  let date = new Date();
  let year = date.getFullYear();
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 600) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <section class="footer-area">
        <svg
          class="footer-svg"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            fill-opacity="1"
            d="M0,64L17.1,64C34.3,64,69,64,103,53.3C137.1,43,171,21,206,21.3C240,21,274,43,309,53.3C342.9,64,377,64,411,85.3C445.7,107,480,149,514,154.7C548.6,160,583,128,617,144C651.4,160,686,224,720,213.3C754.3,203,789,117,823,117.3C857.1,117,891,203,926,218.7C960,235,994,181,1029,149.3C1062.9,117,1097,107,1131,101.3C1165.7,96,1200,96,1234,96C1268.6,96,1303,96,1337,80C1371.4,64,1406,32,1423,16L1440,0L1440,320L1422.9,320C1405.7,320,1371,320,1337,320C1302.9,320,1269,320,1234,320C1200,320,1166,320,1131,320C1097.1,320,1063,320,1029,320C994.3,320,960,320,926,320C891.4,320,857,320,823,320C788.6,320,754,320,720,320C685.7,320,651,320,617,320C582.9,320,549,320,514,320C480,320,446,320,411,320C377.1,320,343,320,309,320C274.3,320,240,320,206,320C171.4,320,137,320,103,320C68.6,320,34,320,17,320L0,320Z"
          ></path>
        </svg>
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-sm-12">
              <div class="footer-item">
                <div class="logo">
                  <a href="/" class="foot-logo">
                    <img src={logo} alt="logo" height="50" />
                  </a>
                </div>
                <p class="footer__desc">
                  At Dotemb, we take pride in being a mid-sized embroidery
                  digitizing company dedicated to transforming creative visions
                  into intricately stitched realities.
                </p>

                <ul class="social-profile pt-4">
                  <li>
                    <a
                      href="https://www.facebook.com/profile.php?id=61554507452112&mibextid=kFxxJD"
                      target="_blank"
                    >
                      <i class="lab la-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://x.com/dotembofficial" target="_blank">
                      <FaXTwitter />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://instagram.com/dotembofficial"
                      target="_blank"
                    >
                      <i class="lab la-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://youtube.com/@dotembofficial"
                      target="_blank"
                    >
                      <i class="lab la-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/dot-emb-3a89222a4?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                      target="_blank"
                    >
                      <i class="lab la-linkedin-in"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-2 col-sm-12">
              <div class="footer-item">
                <h4 class="footer__title">Company</h4>
                <div class="section-divider"></div>
                <ul class="user-links">
                  <li>
                    <a href="/pricing">Pricing</a>
                  </li>
                  <li>
                    <a href="/portfolio">portfolio</a>
                  </li>
                  <li>
                    <a href="/faq">FAQ</a>
                  </li>

                  <li>
                    <a href="/contact">contact us</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-sm-12">
              <div class="footer-item">
                <h4 class="footer__title">Services</h4>
                <div class="section-divider"></div>
                <ul class="user-links">
                  <li>
                    <a href="/embroidery">Embroidery Digitizing</a>
                  </li>
                  <li>
                    <a href="/vector">Vector Art Conversion</a>
                  </li>
                  <li>
                    <a href="/puff">3D / PUFF Digitizing</a>
                  </li>
                  <li>
                    <a href="/applique">Applique Digitizing</a>
                  </li>
                  <li>
                    <a href="/jacket">Jacket Back Digitizing</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-sm-12">
              <div class="footer-item">
                <h4 class="footer__title"></h4>

                <ul class="user-links pt-5">
                  <li>
                    <a href="logo">Logo Digitizing</a>
                  </li>

                  <li>
                    <a href="cap">Cap Digitizing Service</a>
                  </li>
                  <li>
                    <a href="embroiderypatch">Embroidery Patches</a>
                  </li>
                  <li>
                    <a href="sleev">Sleeve Digitizing</a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div class="col-lg-4 col-sm-6">
                <div class="footer-item">
                    <h4 class="footer__title">Subscribe</h4>
                    <div class="section-divider"></div>
                    <div class="contact-form-action">
                        <p class="contact__text">Enter your e-mail address and subscribe to our newsletter.</p>
                        <form method="post">
                            <div class="form-group">
                                <i class="la la-envelope-o form-icon"></i>
                                <input class="form-control" type="email" name="email" placeholder="Enter your email" />
                                <button type="submit" class="theme-btn send__btn">
                                     Subscribe
                                </button>
                            </div>
                        </form>
                    </div>
                    <div class="total-box">
                        <div class="total-item">
                            <h4 class="counter total__num">66,5310</h4>
                            <p class="total__text"><span class="la la-envelope mr-1"></span>Subscribers</p>
                        </div>
                        <div class="total-item">
                            <h4 class="counter total__num">26,315</h4>
                            <p class="total__text"><span class="la la-users mr-1"></span>Lovely Clients</p>
                        </div>
                    </div>
                </div>
            </div> */}
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="copy-right">
                <p class="copy__desc">
                  Copyright © {year} {""}
                  <a href="/" target="_blank">
                    DotembDigitizing
                  </a>{" "}
                  . All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {showScrollButton && (
        <div
          id={`scroll-top ${showScrollButton ? "active" : ""}`}
          onClick={scrollToTop}
        >
          <i class="la la-angle-up" title="Go top"></i>
        </div>
      )}
    </>
  );
}
export default Footer;
