import React from "react";

function CapDigi() {
  return (
    <>
      <section class="about-area about-area2 section-padding">
        <div class="container" style={{ marginTop: "70px" }}>
          {" "}
          <div class="section-heading text-center">
            <h2 class="section__title">Cap & Hat Embroidery Digitizing</h2>
          </div>
          <div class="row pt-5">
            <div class="col-lg-6">
              <div class="about-item about-item-left">
                <ul class="list-items">
                  <li>
                    <span class="la la-certificate"></span>
                    <h3>Cap Embroidery Digitizing Service</h3>
                    <p>
                      {" "}
                      <ul class="list-items">
                        <li>
                          Cap and hat digitizing is a unique and specialized
                          field of embroidery digitizing services and one with
                          high demand nowadays. The complexity involved in the
                          process demands highly expert and experienced
                          digitizers to ensure the precision of stitches and a
                          refined final product, as the slightest of negligence
                          on the part of digitizers can result in the complete
                          redo of the project. Here, you will fetch a
                          high-quality cap digitizing service that will make you
                          come back for more.
                        </li>
                        <li>
                          There are two broad categories of cap and hat
                          digitizing services based on the structure; structured
                          cap & hats and unstructured caps & hats. Both of them
                          encompass a vast range of embroidery designs for caps.
                        </li>
                      </ul>
                    </p>
                  </li>{" "}
                </ul>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="about-item about-item-right">
                <div class="about-item about-item-left">
                  <ul class="list-items">
                    <li>
                      <span class="la la-bullseye"></span>
                      <h3>Structured Cap and Hat Digitizing</h3>
                      <p>
                        <ul class="list-items">
                          <li>
                            {" "}
                            Structured caps and hats refer to six-panel caps.
                            However, if you want to see embroidery on hats, you
                            can view the samples by asking one of our support
                            agents.
                          </li>
                          <li>
                            Two important digitizing considerations involved for
                            structured caps and hats digitizing include;
                            starting from the bottom up and from middle to far
                            ends. Based on the steps involved in digitizing,
                            this technique is also called a center out
                            technique.
                          </li>
                        </ul>
                      </p>
                    </li>{" "}
                    <li>
                      <span class="la la-bullseye"></span>
                      <h3>Unstructured Caps and Hat Digitizing</h3>
                      <p>
                        Caps or hats with five panels are called unstructured
                        caps or hats. As the frame of caps or hats precisely
                        fits the frame, digitization of unstructured caps and
                        hats doesn’t require a center-out technique. However,
                        the non-stiff fabric in such caps and hats requires more
                        precision and adjustment during digitization.{" "}
                      </p>
                    </li>{" "}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>{" "}
      <section class="form-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="contact-img contact-tilt">
                <div class="section-heading">
                  <p class="section__meta">#welcome to Dotemb</p>
                  <h2 class="section__title">
                    Fast, Efficient and Affordable
                    <br />
                    Embroidery Digitizing Services
                  </h2>
                </div>
                <div class="section-description">
                  <p class="section__desc">
                    If you are looking for a digitizing company that can deliver
                    you the peak quality standards and fastest trunaround time,
                    then you are at the right place.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="contact-form-action contact-form-item">
                <p class="section__meta text-center">REQUEST A QUOTE</p>
                <form method="post">
                  {" "}
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-user form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-envelope-o form-icon"></i>
                        <input
                          class="form-control"
                          type="email"
                          name="email"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-phone form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Number"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-pencil-square form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Subject"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group message-group">
                        <i class="la la-pencil form-icon"></i>
                        <textarea
                          class="form-control message-control"
                          placeholder="Message"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group mb-0">
                        <button class="theme-btn" type="button">
                          send message <span class="la la-caret-right"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default CapDigi;
