import React from "react";

function FAQ() {
  return (
    <>
      {" "}
      <section class="accordion-area faq-area bg-gray section-padding ">
        <div class="container " style={{ marginTop: "70px" }}>
          <div class="row ">
            <div class="col-lg-12">
              <div class="section-heading text-center">
                <h5 class="section__meta">documentation</h5>
                <h2 class="section__title">Frequently Asked Questions</h2>
                <div class="section-divider mx-auto"></div>
              </div>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-lg-8">
              <div class="accordion-wrap">
                <div class="accordion" id="accordionExample3">
                  <div class="card">
                    <div class="card-header" id="headingOne">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          <span class="la la-gear"></span>What is embroidery
                          digitizing, and how does Dotemb excel in this field?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseOne"
                      class="collapse "
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample3"
                    >
                      <div class="card-body">
                        Embroidery digitizing is the process of converting
                        artwork into a digital file that an embroidery machine
                        can read. Dotemb stands out by combining cutting-edge
                        technology with skilled professionals to deliver
                        high-quality digitized designs.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingTwo">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          <span class="la la-gear"></span> What types of
                          embroidery digitizing services does Dotemb offer?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      class="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample3"
                    >
                      <div class="card-body">
                        Dotemb offers a wide range of digitizing services,
                        including logo digitizing, custom embroidery digitizing,
                        3D puff digitizing, and more. Our services cater to
                        diverse embroidery needs. single-origin coffee nulla
                        assumenda shoreditch et.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          <span class="la la-gear"></span>How can I submit my
                          artwork for digitizing with Dotemb?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseThree"
                      class="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample3"
                    >
                      <div class="card-body">
                        You can easily submit your artwork through our website.
                        Visit the "Submit Artwork" page and follow the
                        instructions to upload your design. Our team will review
                        it and get back to you promptly.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingFour">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          <span class="la la-gear"></span> What file formats
                          does Dotemb accept for artwork submission?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseFour"
                      class="collapse"
                      aria-labelledby="headingFour"
                      data-parent="#accordionExample3"
                    >
                      <div class="card-body">
                        Dotemb accepts commonly used file formats such as JPEG,
                        PNG, AI, EPS, and PDF. If you have a different format,
                        feel free to reach out to our customer support for
                        assistance.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingFive">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          <span class="la la-gear"></span> What is the typical
                          turnaround time for embroidery digitizing at Dotemb?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseFive"
                      class="collapse"
                      aria-labelledby="headingFive"
                      data-parent="#accordionExample3"
                    >
                      <div class="card-body">
                        Our standard turnaround time is couple of hours, but it
                        may vary based on the complexity of the design and
                        current order volume. For urgent requests, we also offer
                        expedited services.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingSix">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          <span class="la la-gear"></span> How does Dotemb
                          ensure the quality of digitized embroidery designs?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseSix"
                      class="collapse"
                      aria-labelledby="headingSix"
                      data-parent="#accordionExample3"
                    >
                      <div class="card-body">
                        Dotemb employs a team of experienced digitizers who
                        carefully review each design to ensure accuracy and
                        quality. We also use state-of-the-art software and
                        equipment to achieve the best results.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingSeven">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseSeven"
                        >
                          <span class="la la-gear"></span>What are the pricing
                          options for embroidery digitizing services at Dotemb?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseSeven"
                      class="collapse"
                      aria-labelledby="headingSeven"
                      data-parent="#accordionExample3"
                    >
                      <div class="card-body">
                        Our pricing is competitive and varies depending on
                        factors such as design complexity and size. You can find
                        detailed pricing information on our website or contact
                        our sales team for a personalized quote.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingEight">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseElight"
                          aria-expanded="false"
                          aria-controls="collapseElight"
                        >
                          <span class="la la-gear"></span> Does Dotemb offer
                          discounts for bulk orders or recurring clients?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseElight"
                      class="collapse"
                      aria-labelledby="headingEight"
                      data-parent="#accordionExample3"
                    >
                      <div class="card-body">
                        Yes, Dotemb provides discounts for bulk orders and
                        offers special promotions for recurring clients. Contact
                        our sales team to discuss your specific needs and
                        explore available discounts.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingNine">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseNine"
                          aria-expanded="false"
                          aria-controls="collapseNine"
                        >
                          <span class="la la-gear"></span> Can Dotemb
                          accommodate rush orders or expedited digitizing
                          services?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseNine"
                      class="collapse"
                      aria-labelledby="headingNine"
                      data-parent="#accordionExample3"
                    >
                      <div class="card-body">
                        Absolutely! Dotemb understands the importance of tight
                        deadlines. We offer rush services for clients with
                        urgent requirements. Please contact our customer support
                        for assistance with expedited orders.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingTen">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTen"
                          aria-expanded="false"
                          aria-controls="collapseTen"
                        >
                          <span class="la la-gear"></span>How can I track the
                          progress of my digitizing order at Dotemb?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTen"
                      class="collapse"
                      aria-labelledby="headingTen"
                      data-parent="#accordionExample3"
                    >
                      <div class="card-body">
                        You can track the progress of your order by logging into
                        your account on our website. We provide regular updates
                        on the status of your digitizing project, from
                        submission to completion.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="faq-img">
                <img src="assets/images/workspace.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default FAQ;
