import React from "react";

function Embroidery() {
  return (
    <>
      <section class="about-area about-area2 section-padding">
        <div class="container" style={{ paddingTop: "100px" }}>
          <div class="section-heading text-center">
            <h2 class="section__title">Embroidery Digitizing</h2>
          </div>
          <div class="row" style={{ paddingTop: "50px" }}>
            <div class="col-lg-6">
              <div class="about-item about-item-left">
                <ul class="list-items">
                  <li>
                    <span class="la la-certificate"></span>
                    <h3>Best Embroidery Digitizing Services</h3>
                    <p>
                      Dotemb Digitizing is a leading full in-house embroidery
                      digitizing service in the USA, offering the best and sheer
                      brilliant custom embroidery digitizing services to local
                      and international clients. We have one of the most
                      experienced and talented teams of experts, well-resourced
                      to handle embroidery digitizing designs of all sizes and
                      complexities.
                    </p>
                  </li>{" "}
                  <li>
                    <span class="la la-lightbulb-o"></span>
                    <h3>Committed to Your Success </h3>
                    <p>
                      We are one of the pioneers of embroidery digitizing
                      services in the USA, providing top-notch custom digitized
                      embroidery designs. With over a decade of experience,
                      similarly, we have seen the ups and downs of the industry
                      and have kept our pace to ensure integrating the latest
                      technologies that change the face of the service.
                      <br />
                      While we are known as the "perfectionists" by our clients,
                      we have struggled very hard to gain our clients' trust.
                      You can count on our custom embroidery designs to deliver
                      you nothing but brilliance, and brilliance is our middle
                      name when it comes to embroidery digitizing services.
                      <br />
                      We work as hard as you make sure you stay at the top of
                      the competitive curve with our unique and advanced
                      digitizing solutions.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="about-item about-item-right">
                <div class="about-item about-item-left">
                  <ul class="list-items">
                    <li>
                      <span class="la la-bullseye"></span>
                      <h3>
                        Get Your Design Embroidered with the Latest Technology
                      </h3>
                      <p>
                        With decades of experience serving the industry, we have
                        the best cutting-edge resources (hardware and software)
                        to ensure superior quality, reliability, and aesthetics
                        in our embroidered designs. We frequently exceed your
                        expectations with our quality, timely service, and
                        affordability that are digitized for embroidery.
                      </p>
                    </li>
                    <li>
                      <span class="la la-users"></span>
                      <h3>
                        Fast, Efficient, and Affordable Custom Embroidery
                        Digitizing Services{" "}
                      </h3>
                      <p>
                        If you are looking for a digitizing company that can
                        deliver you the peak quality standards and fastest
                        turnaround time, then you are at the right place. At
                        Dotemb Digitizing, we are all about delivering reliable,
                        efficient, and affordable custom embroidery digitizing
                        services to our clients in the USA, Canada, UK, and
                        Australia. Our enchanting embroidered designs are meant
                        to embezzle you and your clients with their depth and
                        attention to detail.
                      </p>
                    </li>{" "}
                    <li>
                      <span class="la la-users"></span>
                      <h3>Uncompromised Quality – Unbeatable Passion</h3>
                      <p>
                        At Dotemb Digitizing, it's all about bringing the same
                        passion to our work day after day. We take pride in
                        delivering the most amazing, high-quality embroidery
                        digitizing work at unbeatable affordable pricing. With
                        some of the most talented and passionate digitizers in
                        our team, we challenge ourselves each day to enhance our
                        services and come up with even better results for our
                        clients.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>{" "}
      <section class="form-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="contact-img contact-tilt">
                <div class="section-heading">
                  <p class="section__meta">#welcome to Dotemb</p>
                  <h2 class="section__title">
                    Fast, Efficient and Affordable
                    <br />
                    Embroidery Digitizing Services
                  </h2>
                </div>
                <div class="section-description">
                  <p class="section__desc">
                    If you are looking for a digitizing company that can deliver
                    you the peak quality standards and fastest trunaround time,
                    then you are at the right place.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="contact-form-action contact-form-item">
                <p class="section__meta text-center">REQUEST A QUOTE</p>
                <form method="post">
                  {" "}
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-user form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-envelope-o form-icon"></i>
                        <input
                          class="form-control"
                          type="email"
                          name="email"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-phone form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Number"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-pencil-square form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Subject"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group message-group">
                        <i class="la la-pencil form-icon"></i>
                        <textarea
                          class="form-control message-control"
                          placeholder="Message"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group mb-0">
                        <button class="theme-btn" type="button">
                          send message <span class="la la-caret-right"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Embroidery;
