import React from "react";

function Pricing() {
  return (
    <>
      {" "}
      <section class="form-area pt-5">
        <div class="container pt-5">
          <div class="row pt-5">
            <div class="col-lg-12 pt-5">
              <div class="section-heading text-center">
                <h5 class="section__meta">pricing plan</h5>
                <h2 class="section__title">
                  Pick Package That Suits Your Needs
                </h2>
              </div>
            </div>
          </div>
          <div class="row package-content-wrap pt-5">
            <div class="col-lg-4">
              <div class="package-item package-item-2 package-item-active-2">
                {/* <div class="package-tooltip">
                  <span class="package__tooltip">Popular</span>
                </div> */}
                <div class="package-title">
                  <h3 class="package__title">Embroidery Digitizing</h3>
                  <h3 class="package__price">
                    <span style={{ color: "black", fontSize: "15px" }}>
                      flat
                    </span>{" "}
                    <span>$</span>3{" "}
                    <span style={{ color: "black", fontSize: "15px" }}>
                      for 6 Thousand stitches
                    </span>
                  </h3>
                </div>
                <ul class="package-list">
                  <li>1.0 per Two Thousand stitches</li>
                  <li>Minor Edits/Revision Free</li>
                  <li>Free Size Change Upto 10 to 20%</li>
                  <li>Free Urgent Service</li>
                </ul>
                <div class="package-price">
                  {/* <a href="#" class="theme-btn">
                    Purchase
                  </a>
                  <p class="package__meta">14 days money back guarantee!</p> */}
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="package-item package-item-2">
                <div class="package-title">
                  <h3 class="package__title">Jacket Back</h3>
                  <h3 class="package__price">
                    <span>$</span>10
                    <span style={{ color: "black", fontSize: "15px" }}>
                      Minimum
                    </span>
                  </h3>{" "}
                  <h3 class="package__price">
                    <span style={{ color: "black", fontSize: "15px" }}>
                      Maximum
                    </span>{" "}
                    <span>$</span>50
                  </h3>
                </div>
                <ul class="package-list">
                  <li>1.0 Per Thousand Stitches</li>
                  <li>Minor Edits/Revisions Free</li>
                  <li>Free size change upto 10 to 20%</li>
                </ul>
                <div class="package-price">
                  {/* <a href="#" class="theme-btn">
                    Purchase
                  </a>
                  <p class="package__meta">14 days money back guarantee!</p> */}
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="package-item package-item-2">
                <div class="package-title">
                  <h3 class="package__title">Vector Art</h3>
                  <h3 class="package__price">
                    <span style={{ color: "black", fontSize: "15px" }}>
                      flat
                    </span>{" "}
                    <span>$</span>7
                    <span style={{ color: "black", fontSize: "15px" }}>
                      for Vector Art Conversion
                    </span>
                  </h3>
                </div>
                <ul class="package-list">
                  <li>For Complex Depend upon its complexity</li>
                  <li>Minor Edits/Revision Free</li>
                  <li>Free urgent service</li>
                </ul>
                <div class="package-price">
                  {/* <a href="#" class="theme-btn">
                    Purchase
                  </a> */}
                  {/* <p class="package__meta">14 days money back guarantee!</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>{" "}
    </>
  );
}
export default Pricing;
