import logo from './logo.svg';
// import './App.css';
import Mainroutes from './Routes/MainRoutes';
function App() {
  return (
   <> <Mainroutes /></>
  );
}

export default App;
