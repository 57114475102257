import React from "react";

function Jacket() {
  return (
    <>
      <section class="about-area about-area2 section-padding">
        <div class="container" style={{ marginTop: "70px" }}>
        <div class="section-heading text-center">
            <h2 class="section__title">Jacket Back Digitizing</h2>
          </div>
          <div class="row pt-5">
            <div class="col-lg-6">
              <div class="about-item about-item-left">
                <ul class="list-items">
                  <li>
                    <span class="la la-certificate"></span>
                    <h3>Jacket Back Digitizing</h3>
                    <p>
                      At Dotemb Digitizing, you get a plethora of digitizing
                      services, such as:
                      <ul>
                        <li>Jacket Back Digitizing</li>
                        <li>Vectorizing Bitmap images</li>
                        <li>Complex Digitizing</li>
                        <li>Complex Digitizing Services</li>
                        <li>Embroidery Digitizing</li>
                        <li>Custom Embroidery Digitizing</li>
                        <li>Embroidery Digitized Designs</li>
                      </ul>
                      Jacket back digitizing is one of the specialized fields of
                      embroidery digitizing services and includes artwork and
                      designs specifically digitized to be stitched on the back
                      of leather jackets. The exquisiteness and elusive nature
                      of jacket back digitizing makes it a highly sought-after
                      artwork among creative and artistic people. At Dotemb
                      digiting, we ensure the highest quality standards of our
                      digitized files for the most authentic and artistic feel
                      on jacket backs.
                    </p>
                  </li>{" "}
                  <li>
                    <span class="la la-certificate"></span>
                    <h3>Dotemb Digitizing - Outperforming Expectations!</h3>
                    <p>
                      At Dotemb Digitizing, we are known for our commitment to
                      deliverance and astounding quality embroidery digitizing.
                      No matter how complex digitizing work you may have, our
                      skilled and talented custom embroidery digitizers are
                      fully capable of delivering you exceptional quality
                      digitized designs that will surely outperform your
                      expectations.
                    </p>
                  </li>{" "}
                </ul>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="about-item about-item-right">
                <div class="about-item about-item-left">
                  <ul class="list-items">
                    <li>
                      <span class="la la-bullseye"></span>
                      <h3>A Digitization Service Worthy Of Its Value!</h3>
                      <p>
                        <ul class="list-items">
                          <li>
                            Digitizing for jacket back is a complex process that
                            needs extensive training and years of experience to
                            master it fully. It’s one unique component that
                            requires extreme concentration, skill, and mastery
                            to be executed perfectly.
                          </li>
                          <li>
                            Only the most experienced and skilled technician is
                            able to make precise stitches that adhere to the
                            high standards required for jacket back designs.
                            Vectorizing bitmap images for jacket back designs
                            can comprise as many as 40k to 150k stitches, and
                            any negligence will have its effects on the final
                            design, especially when the design is stretched or
                            comprise more than 20%.
                          </li>
                          <li>
                            That’s one reason we have invested heavily and
                            handpicked digitizers to ensure superior quality
                            custom embroidery digitizing services.
                          </li>
                        </ul>
                      </p>
                    </li>{" "}
                    <li>
                      <span class="la la-bullseye"></span>
                      <h3>Privacy – Rest Assured!</h3>
                      <p>
                        We understand the sensitivity circling the privacy and
                        copyright issues and how bad our negligence can impact
                        our customers. That’s one reason why we have stayed
                        truest to our principle of maintaining the highest
                        standards in terms of privacy and copyright protection
                        of embroidered back jacket designs. We never, never ever
                        share embroidery digitized designs to anyone at any
                        cost.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>{" "}
      <section class="form-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="contact-img contact-tilt">
                <div class="section-heading">
                  <p class="section__meta">#welcome to Dotemb</p>
                  <h2 class="section__title">
                    Fast, Efficient and Affordable
                    <br />
                    Embroidery Digitizing Services
                  </h2>
                </div>
                <div class="section-description">
                  <p class="section__desc">
                    If you are looking for a digitizing company that can deliver
                    you the peak quality standards and fastest trunaround time,
                    then you are at the right place.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="contact-form-action contact-form-item">
                <p class="section__meta text-center">REQUEST A QUOTE</p>
                <form method="post">
                  {" "}
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-user form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-envelope-o form-icon"></i>
                        <input
                          class="form-control"
                          type="email"
                          name="email"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-phone form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Number"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-pencil-square form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Subject"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group message-group">
                        <i class="la la-pencil form-icon"></i>
                        <textarea
                          class="form-control message-control"
                          placeholder="Message"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group mb-0">
                        <button class="theme-btn" type="button">
                          send message <span class="la la-caret-right"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Jacket;
