import React from "react";

function Applique() {
  return (
    <>
      <section class="about-area about-area2 section-padding">
        <div class="container" style={{ marginTop: "70px" }}>
          {" "}
          <div class="section-heading text-center">
            <h2 class="section__title">Applique Digitizing </h2>
          </div>
          <div class="row pt-5">
            <div class="col-lg-6">
              <div class="about-item about-item-left">
                <ul class="list-items">
                  <li>
                    <span class="la la-certificate"></span>
                    <h3>Applique Digitizing Service</h3>
                    <p>
                      <ul class="list-items">
                        <li>
                          {" "}
                          Applique digitizing is an amazing technique to add
                          niche and elegance to garments. It’s one of the most
                          popular and highly in-demand artworks that is used to
                          decorate t-shirts, bags, shirts, apparel, and various
                          other decorative items. Here, digitizing applique is a
                          work of art provided by our expert artists.
                        </li>
                        <li>
                          {" "}
                          Here’s your chance to give exquisiteness and style to
                          your wardrobe with applique digitizing services from
                          Dotemb Digitizing. To further enhance your experience
                          of making the perfect purchase, we utilize applique
                          designs for embroidery machines that guarantee you the
                          utmost satisfaction.
                        </li>
                        <li>
                          {" "}
                          Get your custom applique embroidery designs ready in a
                          week!
                        </li>
                      </ul>
                    </p>
                  </li>{" "}
                  <li>
                    <span class="la la-lightbulb-o"></span>
                    <h3>Mastering the Art of Applique Digitizing</h3>
                    <p>
                      <ul class="list-items">
                        <li>
                          {" "}
                          While applique digitizing isn’t the easiest of
                          services, we are fortunate to have some of the best
                          digitizers who mean business. Each of our digitizers
                          is hand-picked for its passion, skills, and experience
                          in the industry that ensures superior designs every
                          time.
                        </li>
                        <li>
                          {" "}
                          We are fully resourced to accept all orders and
                          transform any artwork into seamlessly digitized
                          machine-readable files.
                        </li>
                      </ul>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="about-item about-item-right">
                <div class="about-item about-item-left">
                  <ul class="list-items">
                    <li>
                      <span class="la la-lightbulb-o"></span>
                      <h3>
                        Dotemb Digitizing – No One Does Custom Applique
                        Embroidery Better!{" "}
                      </h3>
                      <p>
                        <ul class="list-items">
                          <li>
                            {" "}
                            Dotemb Digitizing is one of the pioneers in offering
                            applique digitizing services in the USA. We are a
                            leading company that is highly proud to have served
                            national and international clients with the most
                            amazing, highly reliable, and affordable services
                            for many decades. What makes us different is our
                            command of all aspects of digitizing services,
                            including; embroidery digitizing, custom logo
                            digitizing, cap digitizing, 3D puff digitizing, 3D
                            applique embroidery designs, and much more.
                          </li>
                          <li>
                            Our highly trained and experienced digitizers know
                            the art of creating the most amazing artwork over
                            and over again, irrespective of the complexity or
                            intricacy of the original design. We are committed
                            to ensuring the highest quality standard and 100%
                            customer satisfaction for all our projects.{" "}
                          </li>
                          <li>
                            {" "}
                            Our streamlined operation ensures a seamless and
                            smooth completion of work from booking confirmation
                            to final delivery of all digitized vector files,
                            including embroidery applique designs.
                          </li>
                        </ul>
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="form-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="contact-img contact-tilt">
                <div class="section-heading">
                  <p class="section__meta">#welcome to Dotemb</p>
                  <h2 class="section__title">
                    Fast, Efficient and Affordable
                    <br />
                    Embroidery Digitizing Services
                  </h2>
                </div>
                <div class="section-description">
                  <p class="section__desc">
                    If you are looking for a digitizing company that can deliver
                     the peak quality standards and fastest trunaround time,
                    then you are at the right place.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="contact-form-action contact-form-item">
                <p class="section__meta text-center">REQUEST A QUOTE</p>
                <form method="post">
                  {" "}
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-user form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-envelope-o form-icon"></i>
                        <input
                          class="form-control"
                          type="email"
                          name="email"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-phone form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Number"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <i class="la la-pencil-square form-icon"></i>
                        <input
                          class="form-control"
                          type="text"
                          name="text"
                          placeholder="Subject"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group message-group">
                        <i class="la la-pencil form-icon"></i>
                        <textarea
                          class="form-control message-control"
                          placeholder="Message"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group mb-0">
                        <button class="theme-btn" type="button">
                          send message <span class="la la-caret-right"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Applique;
